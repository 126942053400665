import React, { useContext } from 'react'

import { Subheading2 } from '@lumoslabs/lumosity-storybook'
import { useSpring } from '@react-spring/core'
import { animated } from '@react-spring/web'
import styled from 'styled-components'

import WorkoutDonut from '~/components/workout/WorkoutDonut'
import { HeaderTypes } from '~/constants'
import { AuthContext } from '~/context/AuthContext'
import { GameContext } from '~/context/GameContext'
import { GetTodaysWorkoutQuery } from '~/graphql/generated/schema'
import { getWorkoutSlotForSlug, getTotalGamesCount, getTotalGamesPlayed } from '~/utils/workoutUtils'

export interface WorkoutHeaderProps {
  headerType: HeaderTypes
  workoutData: GetTodaysWorkoutQuery
}

const WorkoutHeader: React.FC<WorkoutHeaderProps> = ({ headerType, workoutData }) => {
  const { slug } = useContext(GameContext)
  const donutAnimation = useSpring({
    from: { transform: 'translate3d(250px,0,0) rotateZ(120deg)' },
    to: { transform: 'translate3d(0px,0,0) rotateZ(0deg)' },
  })

  const { hasPremium } = useContext(AuthContext)

  const totalGameCount = getTotalGamesCount(hasPremium)

  const currentSlot = getWorkoutSlotForSlug(workoutData, slug)
  const totalGamesPlayed = getTotalGamesPlayed(workoutData)
  let currentGamePosition = Math.min(totalGamesPlayed + 1, totalGameCount)

  //game is being replayed
  if (currentSlot?.lastGamePlay && totalGamesPlayed != totalGameCount) currentGamePosition--

  return (
    <WorkoutContainer>
      <animated.div style={{ ...donutAnimation, width: '40px', height: '40px' }}>
        <WorkoutDonut workout={workoutData} headerType={headerType} />
      </animated.div>
      <Counter>
        <CounterValue>{currentGamePosition}</CounterValue>
      </Counter>
    </WorkoutContainer>
  )
}

const WorkoutContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.neutral[100]};
  border-radius: 40px;
  display: flex;
  align-items: center;
  width: 80px;
  background: ${({ theme }) => theme.colorTokens.surface['surface-default']};
`

const Counter = styled.div`
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.colors.neutral[400]};
  padding: 8px;
`
const CounterValue = styled(Subheading2)`
  display: inline-block;
`

export default WorkoutHeader
