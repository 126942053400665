import { LIMITED_WORKOUT_GAME_TOTAL, PlayContext, PREMIUM_WORKOUT_GAME_TOTAL } from '~/constants'
import { GetTodaysWorkoutQuery } from '~/graphql/generated/schema'

export const mapWorkout = (workout: { id: any; slots: any; createdAt?: any }, hasPremium = true): Workout => {
  // Most uses of this function need the entire workout so Premium is the default, for getNextUnplayedGame we'll use the correct sub status
  const slots = hasPremium
    ? {
        slot1: workout.slots.slot1,
        slot2: workout.slots.slot2,
        slot3: workout.slots.slot3,
        slot4: workout.slots.slot4,
        slot5: workout.slots.slot5,
      }
    : {
        slot1: workout.slots.slot1,
        slot2: workout.slots.slot2,
        slot5: workout.slots.slot5,
      }

  const mappedWorkout: Workout = {
    id: workout.id,
    createdAt: workout.createdAt,
    slots: slots,
  }

  return mappedWorkout
}

export const getWorkoutSlotForSlug = (queryResult: GetTodaysWorkoutQuery, slug: string) => {
  const { slots } = mapWorkout(queryResult.me.todaysWorkout)

  const currentSlot = Object.values(slots).find((slot) => slot.game.slug === slug)

  return currentSlot
}

export const getLastPlayedGame = (queryResult: GetTodaysWorkoutQuery, hasPremium: boolean) => {
  const { slots } = mapWorkout(queryResult.me.todaysWorkout, hasPremium)
  const lastPlayedGames = Object.values(slots).filter((slot) => slot.lastGamePlay)
  return lastPlayedGames[lastPlayedGames.length - 1]
}

export const getNextUnplayedGame = (queryResult: GetTodaysWorkoutQuery, hasPremium: boolean) => {
  const { slots } = mapWorkout(queryResult.me.todaysWorkout, hasPremium)

  return Object.values(slots).find((slot) => !slot.lastGamePlay) || null
}

export const getTotalGamesPlayed = (queryResult: GetTodaysWorkoutQuery) => {
  const { slots } = mapWorkout(queryResult.me.todaysWorkout)
  // Filter out games that do not have a gameplay and only count those that do
  const totalPlayedGames = Object.values(slots).filter((slot) => {
    return slot.lastGamePlay
  }).length

  return totalPlayedGames
}

export const getTotalGamesCount = (hasPremium: boolean) => {
  return hasPremium ? PREMIUM_WORKOUT_GAME_TOTAL : LIMITED_WORKOUT_GAME_TOTAL
}

export const getCompletedGameSlugs = (queryResult: GetTodaysWorkoutQuery, hasPremium: boolean) => {
  const { slots } = mapWorkout(queryResult.me.todaysWorkout, hasPremium)
  return Object.values(slots)
    .filter((slot) => slot.lastGamePlay !== null)
    .map((workoutGame) => workoutGame.game.slug as GameSlug)
}

export const handleWorkoutComplete = (
  queryResult: GetTodaysWorkoutQuery,
  hasPremium: boolean,
  playContext: PlayContext,
  callbackFn: (workoutGameSlugs: GameSlug[]) => any,
) => {
  const isWorkoutComplete = getNextUnplayedGame(queryResult, hasPremium)?.game === undefined
  if (playContext === PlayContext.Workout && isWorkoutComplete) {
    const gameSlugs = getCompletedGameSlugs(queryResult, hasPremium)
    callbackFn(gameSlugs)
  }
}

/**
 * getWorkoutMeta will be 1 place to extract out all useful information from todaysWorkout.
 *
 * TODO: In this file, in multiple functions, we are looping todaysWorkout, to extract different kind of information from same data
 * example : getCompletedGameSlugs, getTotalGamesPlayed, getNextUnplayedGame
 * TODO: Remove all redundant functions & adjust the code.
 * This function should loop todaysWorkout 1 time & should extract all necessary info at once
 */
export const getWorkoutMeta = ({
  queryResult,
  hasPremium,
}: {
  queryResult?: GetTodaysWorkoutQuery
  hasPremium: boolean
}) => {
  if (!queryResult?.me?.todaysWorkout) return null
  const { slots } = mapWorkout(queryResult.me.todaysWorkout, hasPremium)
  let totalGamePlayed = 0
  const algorithmVersion = queryResult?.me?.todaysWorkout?.algorithmVersion || ''
  const allSlugs: Array<string> = []
  Object.values(slots).forEach((slot) => {
    allSlugs.push(slot.game.slug)
    if (slot.lastGamePlay) totalGamePlayed = totalGamePlayed + 1
  })

  return {
    allSlugs,
    totalGamePlayed,
    isFirstGame: totalGamePlayed === 0,
    isLastGame: totalGamePlayed === allSlugs.length - 1,
    algorithmVersion,
  }
}
