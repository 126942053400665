import React, { useContext, useState } from 'react'

import { BodyText1SemiBold } from '@lumoslabs/lumosity-storybook'
import Tippy from '@tippyjs/react/headless'
import Link from 'next/link'
import styled from 'styled-components'

import LogoutModal from '~/components/auth/LogoutModal'
import StreakCounter from '~/components/streaks/StreakCounter'
import ButtonBackToGames from '~/components/ui/ButtonBackToGames'
import ButtonQuitWorkout from '~/components/ui/ButtonQuitWorkout'
import DropdownBox from '~/components/ui/DropdownBox'
import WorkoutHeader from '~/components/workout/WorkoutHeader'
import { AppDimensions, HeaderTypes, UserHelpCenterUrl } from '~/constants'
import { AuthContext } from '~/context/AuthContext'
import { EventClick, EventScreenName } from '~/events/eventTypes'
import useTrackClick from '~/events/trackers/useTrackClick'
import { useGetUserQuery } from '~/graphql/generated/schema'
import useTodaysWorkout from '~/hooks/useTodaysWorkout'
import { useTranslationForNamespace } from '~/hooks/useTranslationForNamespace'
import useUpdateAccountState from '~/hooks/useUpdateAccountState'
import BrandTitle from '~/images/icons/Brand/LumosityText.svg'
import ChevronDown from '~/images/icons/SystemOutlined/ChevronDown.svg'
import ChevronUp from '~/images/icons/SystemOutlined/ChevronUp.svg'
import Close from '~/images/icons/SystemOutlined/Close.svg'
import SettingsCog from '~/images/icons/SystemOutlined/Gear.svg'
import Help from '~/images/icons/SystemOutlined/HelpCircle.svg'
import LogOut from '~/images/icons/SystemOutlined/Logout.svg'
import Hamburger from '~/images/icons/SystemOutlined/Trigram.svg'

enum ButtonTypes {
  lumos = 'lumos',
  lumosWhite = 'lumosWhite',
  freeplayPreGame = 'freeplayPreGame',
  freeplayPostGame = 'freeplayPostGame',
  quitWorkoutWhite = 'quitWorkoutWhite',
  stepGame = 'stepGame',
  empty = 'empty',
  quitWorkoutGrey = 'quitWorkoutGrey',
}

export interface TopNavProps {
  headerType?: HeaderTypes
  headerButtonComponent?: React.ReactNode
  className?: string
}

const HeaderNavButton = ({ buttonType }: { buttonType: ButtonTypes }) => {
  switch (buttonType) {
    case ButtonTypes.empty:
      return <></>
    case ButtonTypes.lumos:
      return (
        <Link href='/' passHref>
          <StyledBrandTitle title='Lumosity' width={122} height={31} />
        </Link>
      )
    case ButtonTypes.lumosWhite:
      return (
        <Link href='/' passHref>
          <StyledBrandTitle $isWhite={true} title='Lumosity' width={122} height={31} />
        </Link>
      )
    case ButtonTypes.freeplayPreGame:
      return (
        <Link href='/games' passHref>
          <ButtonBackToGames $isWhite={true} />
        </Link>
      )
    case ButtonTypes.freeplayPostGame:
      return (
        <Link href='/games' passHref>
          <ButtonBackToGames />
        </Link>
      )
    case ButtonTypes.quitWorkoutWhite:
      return (
        <Link href='/' passHref>
          <ButtonQuitWorkout $isWhite={true} />
        </Link>
      )
    case ButtonTypes.quitWorkoutGrey:
      return (
        <Link href='/' passHref>
          <ButtonQuitWorkout />
        </Link>
      )
    // case ButtonTypes.stepGame: // for stepGame we pass button from component
    default:
      return <></>
  }
}

const getHeaderConfig = (headerType: HeaderTypes) => {
  // Add header types to this list that shouldn't have the header stuck to the top of the page when you scroll down
  const sticky = ![
    HeaderTypes.login,
    HeaderTypes.survey,
    HeaderTypes.postWorkoutGame,
    HeaderTypes.preWorkoutGame,
  ].includes(headerType)

  // Add header types to this list that should show the streak flame and count
  const showStreaks = [
    HeaderTypes.lumos,
    HeaderTypes.preFitTestGame,
    HeaderTypes.subscribe,
    HeaderTypes.postWorkoutGame,
    HeaderTypes.freeplayPostGame,
  ].includes(headerType)

  // Add header types here that should show your name with the dropdown menu
  const showName = [
    HeaderTypes.lumos,
    HeaderTypes.preFitTestGame,
    HeaderTypes.subscribe,
    HeaderTypes.survey,
    HeaderTypes.legal,
  ].includes(headerType)

  // Show white text for Fit Test header name and logo
  const isFitTest = headerType === HeaderTypes.preFitTestGame
  // Add header types here that should show the workout progress donut
  const showWorkout = [HeaderTypes.preWorkoutGame, HeaderTypes.postWorkoutGame].includes(headerType)

  // Add header types here that should have a transparent background
  const BaseContainer = [
    HeaderTypes.freeplayPreGame,
    HeaderTypes.freeplayPostGame,
    HeaderTypes.preWorkoutGame,
    HeaderTypes.postWorkoutGame,
    HeaderTypes.preFitTestGame,
    HeaderTypes.survey,
  ].includes(headerType)
    ? ContainerTransparent
    : ContainerPrimary

  let primaryButton = ButtonTypes.lumos
  // Add custom button types here
  if (headerType) {
    if (headerType === HeaderTypes.freeplayPostGame) {
      primaryButton = ButtonTypes.freeplayPostGame
    } else if (headerType === HeaderTypes.freeplayPreGame) {
      primaryButton = ButtonTypes.freeplayPreGame
    } else if (headerType === HeaderTypes.empty) {
      primaryButton = ButtonTypes.empty
    } else if (headerType === HeaderTypes.stepGame) {
      primaryButton = ButtonTypes.stepGame
    } else if (headerType === HeaderTypes.preWorkoutGame) {
      primaryButton = ButtonTypes.quitWorkoutWhite
    } else if (headerType === HeaderTypes.postWorkoutGame) {
      primaryButton = ButtonTypes.quitWorkoutGrey
    } else if (headerType === HeaderTypes.preFitTestGame) {
      primaryButton = ButtonTypes.lumosWhite
    }
  }

  return {
    sticky,
    showStreaks,
    showName,
    isFitTest,
    showWorkout,
    BaseContainer,
    primaryButton,
  }
}

const TopNav: React.FC<TopNavProps> = ({ headerType = HeaderTypes.lumos, className, headerButtonComponent }) => {
  const t = useTranslationForNamespace('common')

  const { hasAccountStateOverride } = useContext(AuthContext)
  const { trackCta } = useTrackClick()
  const [showLogoutModal, setShowLogoutModal] = useState(false)

  useUpdateAccountState({ skip: hasAccountStateOverride })

  const { data: userQueryData } = useGetUserQuery({ fetchPolicy: 'cache-and-network' })
  const name = userQueryData?.me?.firstName || '...'

  const handleLogoutInApp = () => {
    trackCta({
      click_name: EventClick.names.LogOut,
      text: t('topNavDropdown.logOut'),
      type: EventClick.types.Link,
      destination: EventScreenName.LogOut,
    })
    setShowLogoutModal(true)
  }

  const skip = ![HeaderTypes.postWorkoutGame, HeaderTypes.preWorkoutGame, HeaderTypes.preFitTestGame].includes(
    headerType,
  )
  const { data } = useTodaysWorkout({ skip })

  const [visible, setVisible] = useState(false)
  const show = () => setVisible(true)
  const hide = () => setVisible(false)

  const { sticky, showName, showStreaks, showWorkout, BaseContainer, primaryButton, isFitTest } =
    getHeaderConfig(headerType)

  const handleClickSettings = () => {
    trackCta({
      click_name: EventClick.names.Settings,
      destination: EventScreenName.Settings,
      text: t('topNavDropdown.settings'),
      type: EventClick.types.Link,
    })
  }

  const handleClickHelp = () => {
    trackCta({
      click_name: EventClick.names.VisitHelpdesk,
      destination: EventScreenName.HelpCenter,
      text: t('topNavDropdown.help'),
      type: EventClick.types.Link,
    })
  }
  return (
    <BaseContainer sticky={sticky} noTopNav={headerType === HeaderTypes.noTopNav} className={className}>
      {headerButtonComponent ? headerButtonComponent : <HeaderNavButton buttonType={primaryButton} />}
      <HeaderGameInfoContainer>
        <StreakAndNameContainer>
          {showStreaks && (
            <NavStreakContainer>
              <StreakCounter />
            </NavStreakContainer>
          )}
          {showName && (
            <Tippy
              visible={visible}
              interactive={true}
              onClickOutside={hide}
              placement='bottom-start'
              render={(attrs) => (
                <div {...attrs}>
                  <DropdownBox paddingHorizontal={0} paddingVertical={0} placement={attrs['data-placement']}>
                    <DropdownItemsContainer>
                      <Link href='/settings' passHref onClick={handleClickSettings}>
                        <FirstDropDownRow>
                          <SettingsCog title='Settings' fontSize={'32px'} />
                          <DropdownItemsText as='div'>{t('topNavDropdown.settings')}</DropdownItemsText>
                        </FirstDropDownRow>
                      </Link>
                      <Divider>
                        <Line x1='0%' x2='100%' y1='50%' y2='50%' />
                      </Divider>
                      <Link href={UserHelpCenterUrl} passHref prefetch={false} onClick={handleClickHelp}>
                        <DropdownRow>
                          <Help title='Help' fontSize={'32px'} />
                          <DropdownItemsText>{t('topNavDropdown.help')}</DropdownItemsText>
                        </DropdownRow>
                      </Link>
                      <Divider>
                        <Line x1='0%' x2='100%' y1='50%' y2='50%' />
                      </Divider>

                      <LastDropDownRow onClick={handleLogoutInApp}>
                        <LogOut title='Log Out' fontSize={'32px'} />
                        <DropdownItemsText>{t('topNavDropdown.logOut')}</DropdownItemsText>
                      </LastDropDownRow>
                      <Divider>
                        <Line x1='0%' x2='100%' y1='50%' y2='50%' />
                      </Divider>
                    </DropdownItemsContainer>
                  </DropdownBox>
                </div>
              )}
            >
              <div onClick={visible ? hide : show} onKeyDown={visible ? hide : show} role='button' tabIndex={0}>
                <NameContainer isFitTest={isFitTest}>
                  <NameText as='div' isFitTest={isFitTest}>
                    {name}
                  </NameText>
                  {!visible && (
                    <>
                      <ChevronContainer isFitTest={isFitTest}>
                        <ChevronDown title='Dropdown' />
                      </ChevronContainer>
                      <HamburgerMenuContainer>
                        <Hamburger title='Menu' fontSize={'48px'} />
                      </HamburgerMenuContainer>
                    </>
                  )}
                  {visible && (
                    <>
                      <ChevronContainer isFitTest={isFitTest}>
                        <ChevronUp title='Dropdown' />
                      </ChevronContainer>
                      <HamburgerMenuContainer>
                        <Close title='Close Menu' fontSize={'32px'} />
                      </HamburgerMenuContainer>
                    </>
                  )}
                </NameContainer>
              </div>
            </Tippy>
          )}
        </StreakAndNameContainer>
        {showWorkout && data && <WorkoutHeader workoutData={data} headerType={headerType} />}
        {showLogoutModal && <LogoutModal onClose={() => setShowLogoutModal(false)} />}
      </HeaderGameInfoContainer>
    </BaseContainer>
  )
}

const Container = styled.div<{ sticky: boolean; noTopNav: boolean }>`
  position: ${({ sticky }) => (sticky ? 'sticky' : 'static')};
  width: 100%;
  top: 0;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${AppDimensions.navBarHeight};
  padding: 0 31px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[100]};
  ${({ noTopNav }) => noTopNav && `display: none`};
`

const NavStreakContainer = styled.span`
  ${({ theme }) => theme.mediaQuery.maxWidth.mobileLarge} {
    display: none;
  }
  background: transparent;
`
const ChevronContainer = styled(NavStreakContainer)<{ isFitTest: boolean }>`
  display: flex;
  color: ${({ theme, isFitTest }) => (isFitTest ? theme.colors.constant.white : theme.colors.neutral[400])};
  ${({ theme }) => theme.mediaQuery.maxWidth.mobileLarge} {
    display: none;
  }
  font-size: 28px;
`

const HamburgerMenuContainer = styled.span`
  ${({ theme }) => theme.mediaQuery.minWidth.mobileLarge} {
    display: none;
  }
  color: ${({ theme }) => theme.colorTokens.icon['icon-default']};
`

const ContainerPrimary = styled(Container)`
  background-color: ${({ theme }) => theme.colors.background.card};
`

const ContainerTransparent = styled(Container)<{ headerType?: HeaderTypes }>`
  width: 100%;
  background: none;
  border-color: ${({ theme }) => theme.colors.neutral[100]}3d;
  border: ${({ headerType }) =>
    headerType === HeaderTypes.preWorkoutGame || headerType === HeaderTypes.preFitTestGame ? '0px' : '1px'};
`

const StreakAndNameContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  ${({ theme }) => theme.mediaQuery.maxWidth.mobileLarge} {
    align-items: flex-end;
  }
`

const NameText = styled(BodyText1SemiBold)<{ isFitTest: boolean }>`
  padding-right: 8px;
  ${({ theme }) => theme.mediaQuery.maxWidth.mobileLarge} {
    display: none;
  }
  ${({ theme, isFitTest }) => isFitTest && `color: ${theme.colors.constant.white}`};
`

const NameContainer = styled.div<{ isFitTest: boolean }>`
  color: ${({ theme }) => theme.colors.neutral[400]};
  cursor: pointer;
  &:hover {
    background: ${({ theme, isFitTest }) => (isFitTest ? theme.colors.warmGray48 : theme.colors.neutral[100])};
  }
  border-radius: 100px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 8px;
  margin-left: 24px;
  ${({ theme }) => theme.mediaQuery.maxWidth.mobileLarge} {
    padding: 0px;
    &:hover {
      background: none;
    }
  }
  transition: all 150ms ease-in-out;
`

const DropdownItemsContainer = styled.div`
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  width: 300px;
  background-color: ${(props) => props.theme.colors.background.card};
  ${({ theme }) => theme.mediaQuery.maxWidth.mobileLarge} {
    overflow-y: auto;
    overflow-x: hidden;
    width: 90vw;
    background-color: ${({ theme }) => theme.colors.neutral[100]};
  }
`

const DropdownItemsText = styled(BodyText1SemiBold)`
  margin-left: 12px;
`

const Divider = styled.svg`
  height: 2px;
  ${({ theme }) => theme.mediaQuery.minWidth.mobileLarge} {
    display: none;
  }
`

const Line = styled.line`
  stroke: ${({ theme }) => theme.colors.neutral[100]};
  stroke-width: 1px;
`

const DropdownRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  height: 58px;
  padding: 12px 16px;
  color: ${({ theme }) => theme.colors.neutral[300]};
  ${({ theme }) => theme.mediaQuery.maxWidth.mobileLarge} {
    color: ${({ theme }) => theme.colors.neutral[300]};
    background-color: ${({ theme }) => theme.colors.background.card};
  }
  ${({ theme }) => theme.mediaQuery.minWidth.mobileLarge} {
    &:hover {
      background-color: ${({ theme }) => theme.colors.neutral[100]};
      > * {
        color: ${({ theme }) => theme.colors.neutral[400]};
      }
      span {
        color: ${({ theme }) => theme.colors.neutral[400]};
      }
    }
  }
`

const FirstDropDownRow = styled(DropdownRow)`
  ${({ theme }) => theme.mediaQuery.minWidth.mobileLarge} {
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
  }
`

const LastDropDownRow = styled(DropdownRow)`
  ${({ theme }) => theme.mediaQuery.minWidth.mobileLarge} {
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
  }
  cursor: pointer;
`

const StyledBrandTitle = styled(BrandTitle)<{ $isWhite?: boolean }>`
  color: ${({ theme, $isWhite }) => ($isWhite ? theme.colors.constant.white : theme.colors.neutral[400])};
`
const HeaderGameInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${({ theme }) => theme.mediaQuery.maxWidth.mobileLarge} {
    justify-content: flex-end;
  }
  align-items: center;
  min-width: 170px;
`

export default TopNav
