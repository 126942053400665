import { createContext } from 'react'

import { PlayContext } from '~/constants'

export const GameContext = createContext<{
  slug: GameSlug
  gameVersion: string
  playContext: PlayContext
  onSlugChange: OnSlugChange
  isSwapped: boolean
  areaSlug: AreaSlug | ''
  /**
   * Current workout ID if we're in a workout (assigned server-side via todaysWorkout)
   */
  workoutId: string
  /**
   * UUID generated during the Game Play Flow, starting with the Screen View where name = pregame.
   * All events triggered through the flow are tied to the same game_session_id.
   * If the user chooses to replay the game a new game_session_id is generated.
   */
  gameSessionId: string
  onChangeGameSessionId?: () => void
}>({
  slug: '' as GameSlug,
  gameVersion: '',
  playContext: PlayContext.FreePlay,
  onSlugChange: () => {
    return
  },
  isSwapped: false,
  areaSlug: '',
  workoutId: '',
  gameSessionId: '',
  onChangeGameSessionId: () => {
    return
  },
})
