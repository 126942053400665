import { useContext, useMemo } from 'react'

import { AuthContext } from '~/context/AuthContext'
import { GameContext } from '~/context/GameContext'
import { MarketingContext } from '~/context/MarketingContext'
import { useGetTodaysWorkoutQuery } from '~/graphql/generated/schema'
import { useAvailableGames } from '~/hooks/useAvailableGames'
import { getWorkoutMeta, handleWorkoutComplete } from '~/utils/workoutUtils'

type UseTodaysWorkoutOptions = {
  skip?: boolean
}

const useTodaysWorkout = ({ skip }: UseTodaysWorkoutOptions = { skip: false }) => {
  const availableGames = useAvailableGames()
  const { hasPremium } = useContext(AuthContext)
  const { playContext } = useContext(GameContext)
  const { logMarketingWorkoutComplete } = useContext(MarketingContext)

  const { data, loading, error } = useGetTodaysWorkoutQuery({
    variables: { knownGameSlugs: availableGames.map(({ gameSlug }) => gameSlug) },
    skip,
    onCompleted: (queryResult) => {
      handleWorkoutComplete(queryResult, hasPremium, playContext, logMarketingWorkoutComplete)
    },
  })

  const workoutMeta = useMemo(() => getWorkoutMeta({ queryResult: data, hasPremium }), [data, hasPremium])

  return { data, loading, error, workoutMeta }
}

export default useTodaysWorkout
